import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { IconsComponent } from '@components/icons/icons.component';
import {
  FADE_ANIMATION,
  OPEN_MODEL_ANIMATION
} from '@constants/animation.constants';
import { REGEX } from '@constants/app.constants';
import { environment } from '@environment/environment';
import { AuthPayload, ForgetPasswordForm } from '@models/auth.model';
import { AuthService } from '@services/auth.service';
import { DialogService } from '@services/dialog.service';
import { EnvironmentLoaderService } from '@services/environment-loader.service';
import { ToasterService } from '@services/toaster.service';
import { VcButtonComponent } from '@vc-libs/vc-button/vc-button.component';
import { VcInputComponent } from '@vc-libs/vc-input/vc-input.component';
import { finalize } from 'rxjs';

const modules = [ReactiveFormsModule];
const components = [VcButtonComponent, VcInputComponent, IconsComponent];

@Component({
  selector: 'app-forgot-password',
  imports: [...modules, ...components],
  templateUrl: './forgot-password.component.html',
  animations: [FADE_ANIMATION, OPEN_MODEL_ANIMATION]
})
export class ForgotPasswordComponent {
  isSubmitted = false;
  fpForm: FormGroup<ForgetPasswordForm>;

  readonly emailRegex = REGEX.EMAIL;
  readonly logo = EnvironmentLoaderService.environmentContent.logo;
  readonly awsUrl = environment.awsUrl;
  readonly destroyRef = inject(DestroyRef);
  constructor(
    private readonly authService: AuthService,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService
  ) {
    this.fpForm = new FormGroup<ForgetPasswordForm>({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX.EMAIL)
      ])
    });
  }

  get formControls() {
    return this.fpForm.controls;
  }

  submitForm(): boolean | void {
    this.fpForm.markAllAsTouched();
    if (this.fpForm.invalid) {
      return;
    }

    this.isSubmitted = true;
    this.authService
      .forgotPassword(this.fpForm.value as Pick<AuthPayload, 'email'>)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => (this.isSubmitted = false))
      )
      .subscribe((res) => {
        this.toasterService.success(res.message);
        this.backToLogin();
      });
  }

  popupClose() {
    this.dialogService.close();
  }

  backToLogin() {
    this.authService.openLogin();
  }
}
