import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { APP } from '@constants/app.constants';
import { fromEvent, merge, Subscription, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Directive({
  selector: '[appIdleTime]',
  standalone: true
})
export class IdleTimeDirective implements OnInit, OnDestroy {
  @Input() idleTime = APP.IDLE_TIMER;
  @Output() idleEvent = new EventEmitter<void>();

  private userActivitySubscription: Subscription = new Subscription();
  private idleTimerSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    const activityEvents = [
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const activityStreams = activityEvents.map((event) =>
      fromEvent(document, event)
    );
    const mergedActivityStream = merge(...activityStreams);
    this.userActivitySubscription = mergedActivityStream
      .pipe(
        switchMap(() => {
          this.idleTimerSubscription.unsubscribe();
          return timer(this.idleTime);
        }),
        tap(() => {
          this.idleEvent.emit();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.userActivitySubscription.unsubscribe();
    this.idleTimerSubscription.unsubscribe();
  }
}
