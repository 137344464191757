import npm from '../../package.json';

export const environment = {
  production: false,
  encryptedKey: process.env.ENCRYPTED_KEY as string,
  recaptchaSecretKey: process.env.CAPTCHA_SECRET_KEY as string,
  type: 'development',
  port: 3012,
  version: npm.version,
  preferredCountries: ['us', 'in'],
  enableImageKit: false,
  baseUrl: 'https://dev-dshm.focalat.com/',
  publicFolder: 'assets/images/',
  awsUrl: 'https://shm-stg.s3.ap-south-1.amazonaws.com/dshm-dev/',
  imageKitUrl: 'https://ik.imagekit.io/7vgmvjj6q/dshm-dev/',
  authApi: 'https://sikh-api.focalat.com/auth',
  collectionApi: 'https://sikh-api.focalat.com/collection',
  contributionApi: 'https://sikh-api.focalat.com/contribution',
  museumEducationApi: 'https://sikh-api.focalat.com/museum-edu-saga',
  cmsApi: 'https://sikh-api.focalat.com/cms',
  cookieDomain: 'localhost'
};
