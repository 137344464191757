import { ViewportScroller } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { APP } from '@constants/app.constants';
import { IdleTimeDirective } from '@directives/idel-time.directive';
import { environment } from '@environment/environment';

import { AuthService } from '@services/auth.service';
import { EnvironmentLoaderService } from '@services/environment-loader.service';
import { SeoService } from '@services/seo.service';
import { SWUpdateService } from '@services/sw-update.service';
import { ToasterService } from '@services/toaster.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, IdleTimeDirective],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  showLoader = true;
  readonly #destroyRef = inject(DestroyRef);
  delayTime = APP.ROUTE_DELAY;
  idleTimer = APP.IDLE_TIMER;

  idleTimeout: number | undefined;
  idlePromptTimeout: number | undefined;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  readonly baseUrl = environment.baseUrl;
  readonly publicFolder = environment.publicFolder;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly toasterService: ToasterService,
    private readonly swUpdateService: SWUpdateService,
    private readonly seoService: SeoService,
    private readonly viewportScroller: ViewportScroller,
    private readonly route: ActivatedRoute,
    private readonly ccService: NgcCookieConsentService
  ) {
    this.swUpdateService.initSW();
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoader = true;
      }
      const eventType = [NavigationEnd, NavigationCancel, NavigationError];
      if (eventType.some((event) => routerEvent instanceof event)) {
        this.showLoader = false;
      }
      if (routerEvent instanceof NavigationEnd) {
        const metaDetail = {
          title: EnvironmentLoaderService.environmentContent.title,
          url: this.baseUrl + this.publicFolder + 'logo.svg'
        };
        this.seoService.updateMetaDescription(metaDetail);
      }
    });
  }

  callConsent() {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {});

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {}
    );

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      () => {}
    );

    this.initializedSubscription = this.ccService.initialized$.subscribe(
      () => {}
    );

    this.initializationErrorSubscription =
      this.ccService.initializationError$.subscribe(() => {});

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      () => {}
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {}
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      () => {}
    );
  }

  onIdle() {
    this.showIdlePrompt();
  }

  checkRouterFragment() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(fragment);
        }, APP.TIMEOUT);
      }
    });
  }

  showIdlePrompt() {
    const userResponse = confirm(
      'You have been idle for a while. Do you want to continue your session?'
    );
    if (userResponse) {
      this.resetIdleTimer();
    } else {
      this.idleTimeout = window.setTimeout(() => {
        window.location.reload();
      }, 0);
    }
  }

  resetIdleTimer() {
    if (this.idleTimeout !== undefined) {
      clearTimeout(this.idleTimeout);
    }
    if (this.idlePromptTimeout !== undefined) {
      clearTimeout(this.idlePromptTimeout);
    }
  }

  ngOnInit() {
    this.toasterService.showLoader$.subscribe((res) => {
      setTimeout(() => {
        this.showLoader = res;
        this.checkRouterFragment();
      }, 0);
    });
    this.authService
      .getSiteSettings()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: (res) => (this.authService.siteSettings = res.data),
        error: () =>
          (this.authService.siteSettings =
            EnvironmentLoaderService.environmentContent.configuration)
      });
    this.callConsent();
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
