import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { inject } from '@angular/core';
import { CACHE_API } from '@constants/api.constants';
import { PRIVATE_VIEW_STATUS_CODE } from '@constants/app.constants';
import { STORAGE } from '@constants/storage.constant';
import { StorageService } from '@services/storage.service';
import { ToasterService } from '@services/toaster.service';
import { catchError, of, tap } from 'rxjs';

export const HttpTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const storageService = inject(StorageService);
  const csrfTokenExtractor = inject(HttpXsrfTokenExtractor);
  const token = storageService.get(STORAGE.LOGIN_TOKEN);

  const csrfToken = csrfTokenExtractor.getToken();
  if (csrfToken && !request.headers.has('XSRF-TOKEN')) {
    request = request.clone({
      setHeaders: {
        'X-XSRF-TOKEN': csrfToken
      }
    });
  }

  if (token) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Timezone-Offset': String(new Date().getTimezoneOffset())
      }
    });
  }

  return next(request);
};

export const HttpErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const toasterService = inject(ToasterService);
  const storageService = inject(StorageService);
  const privateViewStatusCode = PRIVATE_VIEW_STATUS_CODE;

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === HttpStatusCode.Unauthorized) {
        storageService.clear();
      }
      if (error.error?.message) {
        if (
          !(
            request.url.includes('web-private-view') &&
            error.error.statusCode === privateViewStatusCode.PROTECTED_PASSWORD
          )
        ) {
          toasterService.error(error.error.message);
        }
      }
      const err = new HttpErrorResponse({
        error: error.error,
        statusText: error.message,
        status: error.status
      });
      throw err;
    })
  );
};

export const HttpCacheInterceptor: HttpInterceptorFn = (request, next) => {
  const storageService = inject(StorageService);
  const shouldCache = (request: HttpRequest<unknown>) => {
    return (
      request.method === 'GET' &&
      CACHE_API.findIndex((api) => request.url.startsWith(api)) !== -1
    );
  };

  if (shouldCache(request)) {
    const cachedResponse = storageService.cacheAPIs.get(request.url);
    if (cachedResponse) {
      return of(cachedResponse.clone());
    }
  }

  return next(request).pipe(
    tap((response) => {
      if (shouldCache(request) && response instanceof HttpResponse) {
        storageService.cacheAPIs.set(request.url, response.clone());
      }
    })
  );
};
