import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger
} from '@angular/animations';
const TIMINGS = 150;
export const FADE_ANIMATION = trigger('fade1', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate(TIMINGS, style({ opacity: 1 }))
  ])
]);

export const OPEN_MODEL_ANIMATION = trigger('openModel', [
  transition('void => *', [
    style({ top: '100%' }),
    animate(TIMINGS, style({ top: 0 }))
  ])
]);
const staggerTimings = 100;
export const LIST_ANIMATION = trigger('listAnm', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        stagger(staggerTimings, [
          animate(
            '500ms ease-out',
            style({ opacity: 1, transform: 'scale(1)' })
          )
        ])
      ],
      { optional: true }
    )
  ])
]);
