import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { LoginComponent } from '@auth/login/login.component';
import { SignUpComponent } from '@auth/sign-up/sign-up.component';
import { API } from '@constants/api.constants';
import { APP } from '@constants/app.constants';
import { STORAGE } from '@constants/storage.constant';
import { environment } from '@environment/environment';
import { AuthPayload, SignUpPayload, UserDetail } from '@models/auth.model';
import { APIResponseModel } from '@models/common.model';
import { Configs, SiteSettings } from '@models/config.model';
import { StorageService } from '@services/storage.service';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  siteSettings: Partial<SiteSettings> | undefined;
  awsUrl = environment.awsUrl;
  splashDisplay = true;

  private stopMulMantarSubject = new BehaviorSubject<boolean>(false);
  private stopGoldenTempleSubject = new BehaviorSubject<boolean>(false);

  stopMulMantar$: Observable<boolean> =
    this.stopMulMantarSubject.asObservable();
  stopGoldenTemple$: Observable<boolean> =
    this.stopGoldenTempleSubject.asObservable();

  stopMulMantarAudio() {
    this.stopMulMantarSubject.next(true);
  }

  stopGoldenTempleAudio() {
    this.stopGoldenTempleSubject.next(true);
  }

  get initials() {
    const user = this.storageService.get(STORAGE.USER_DATA);
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`;
      const allNames = fullName.trim().split(' ');
      const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');
      return initials;
    }
    return '';
  }

  get isLoggedIn() {
    return !!this.storageService.get(STORAGE.LOGIN_TOKEN);
  }

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private dialogService: DialogService
  ) {}

  login(params: AuthPayload) {
    return this.http.post<APIResponseModel<UserDetail>>(API.LOGIN, params).pipe(
      tap((user) => {
        this.storageService.set(STORAGE.LOGIN_TOKEN, user.data.token);
        this.storageService.set(STORAGE.USER_DATA, {
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          email: user.data.email
        });
      })
    );
  }

  forgotPassword(params: Pick<AuthPayload, 'email'>) {
    return this.http.post<APIResponseModel<null>>(API.FORGOT_PASSWORD, params);
  }

  setPassword(params: Omit<AuthPayload, 'email'>) {
    return this.http.post<APIResponseModel<null>>(API.RESET_PASSWORD, params);
  }

  signUp(params: SignUpPayload) {
    return this.http.post<APIResponseModel<null>>(API.SIGNUP, params);
  }

  getConfig() {
    return this.http.get<APIResponseModel<Configs>>(API.CONFIGS);
  }

  getSiteSettings() {
    return this.http
      .get<APIResponseModel<SiteSettings>>(API.SITE_SETTINGS)
      .pipe(
        tap((res) => {
          const data = res.data;
          data.homeVideoWebPoster = data.homeVideoWebPoster
            ? `${this.awsUrl}${data.homeVideoWebPoster}`
            : `${this.awsUrl}${APP.HOME_VIDEO_WEB_POSTER}`;
          data.homeVideoMobilePoster = data.homeVideoMobilePoster
            ? `${this.awsUrl}${data.homeVideoMobilePoster}`
            : `${this.awsUrl}${APP.HOME_VIDEO_MOBILE_POSTER}`;
          if (data.footerLogo) {
            data.footerLogo = `${this.awsUrl}${data.footerLogo}`;
          }
          if (data.footerLogo) {
            data.headerLogo = `${this.awsUrl}${data.headerLogo}`;
          }
          if (data.goldenTempleHomeAudio) {
            data.goldenTempleHomeAudio = `${this.awsUrl}${data.goldenTempleHomeAudio}`;
          }
          if (data.mulMantarHomeAudio) {
            data.mulMantarHomeAudio = `${this.awsUrl}${data.mulMantarHomeAudio}`;
          }
          if (data.collectionBanner) {
            data.collectionBanner = `${this.awsUrl}${data.collectionBanner}`;
          }
          data.infoEmail ||= APP.INFO_EMAIL;
          data.contactEmail ||= APP.CONTACT_EMAIL;
        })
      );
  }

  emailSubscription(params: { email: string }) {
    return this.http.post<APIResponseModel<UserDetail>>(
      API.EMAIL_SUBSCRIPTION,
      params
    );
  }

  verifyEmail(token: string) {
    return this.http.get<APIResponseModel<null>>(
      `${API.VERIFY_EMAIL}/${token}`
    );
  }

  openLogin(data?: unknown) {
    this.dialogService.dialog$.next({
      component: LoginComponent,
      data
    });
  }

  openSignup() {
    this.dialogService.dialog$.next({
      component: SignUpComponent,
      data: null
    });
  }
}
