/* eslint-disable no-magic-numbers */
export const LANGUAGES = [
  { value: 'en_US', label: 'English' },
  { value: 'de_CH', label: 'German' }
] as const;

export const APP = {
  GALLERY_TIMEOUT: 30,
  GALLERY_LIMIT: 4,
  GALLERY_MOBILE_LIMIT: 2,
  EXPLORE_LIMIT: 5,
  IDLE_TIMER: 3600000,
  SKIP_MODULE: 'shm-collection',
  DETAIL_ROUTE_DELAY: 700,
  ROUTE_DELAY: 1500,
  DEFAULT_IMAGE: 'assets/images/loading.gif',
  DEFAULT_OFFSET: 100,
  INFO_EMAIL: 'info@sikhhistorymuseum.org',
  CONTACT_EMAIL: 'contact@sikhhistorymuseum.org',
  HOME_VIDEO_WEB_POSTER:
    'project-files/home-page-config/default-home-desktop-shot-angle.webp',
  HOME_VIDEO_MOBILE_POSTER:
    'project-files/home-page-config/default-home-mobile-shot-angle.png',
  DEFAULT_SORT_FIELD: 'index',
  DEFAULT_SORT_TYPE: 'asc',
  LOGOUT: 'sign-out',
  LANGUAGE: LANGUAGES[0].value,
  MESSAGE_CHARACTERS: 300,
  ARTEFACT_PAGE_SIZE: 16,
  FEEDBACK_PAGE_SIZE: 10,
  PAGE_INDEX: 1,
  SCROLL_DISTANCE: 1,
  SCROLL_UP_DISTANCE: 2,
  THROTTLE: 300,
  SHORT_TIMEOUT: 350,
  TIMEOUT: 1000,
  VIDEO_TIMEOUT: 1700,
  MINIMUM_WINDOW_WIDTH: 768,
  DEFAULT_DONATION_AMOUNT: 100,
  BREAKPOINTS: [1440, 1280, 1024, 768, 640, 500, 430, 414, 390, 375, 360, 320],
  THUMBNAIL_WIDTH: [110, 97.5, 95, 97, 97.5, 67, 74, 70, 64, 60, 56, 46.5],
  EXPLORE_SLIDE_WIDTH: [
    262.6, 230, 233, 234, 192, 217, 193, 185, 173, 165, 158, 138
  ],
  EXPLORE_GALLERY_SLIDE_WIDTH: [
    158, 230, 233, 234, 192, 217, 193, 185, 173, 165, 158, 138
  ],
  HOME_GALLERY_LIMIT: 7,
  GALLERY_PREFIX: 'GALLERY OF',
  READ_MORE_LIMIT: 250,
  MEDIA_ERR_SRC_NOT_SUPPORTED: 4
};

export const REGEX = {
  EMAIL:
    /* eslint-disable-next-line max-len */
    /^[\p{L}\d!#$%&'*+=?^_`{|}~-]+(?:\.[\p{L}\d!#$%&'*+=?^_`{|}~-]+)*@(?:[_\p{L}\d][-_\p{L}\d]*\.)*(?:[\p{L}\d][-\p{L}\d]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,})$/iu,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/,
  INTEGER: /^\d*$/,
  DECIMAL: /^\d*\.?\d*$/,
  MOBILE:
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
};

export enum ARTEFACT_FILETYPE {
  AUDIO = 'audio',
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document',
  '3D' = '3D',
  FILE = 'file'
}

export type SECTION_TYPES =
  | 'banner'
  | 'text'
  | 'image'
  | 'video'
  | 'textWithImage'
  | 'artefact';

export enum ARTEFACT_MODULE {
  GALLERY = 'gallery',
  HEADING = 'heading',
  CATEGORY = 'category'
}

export enum DAY_TIMEZONE {
  DAWN = 'dawn',
  DAY = 'morning',
  DUSK = 'afternoon',
  NIGHT = 'night'
}

export const PDF_CONFIG = {
  IMG_WIDTH: 210,
  PDF_MARGIN: 5,
  MARGIN_MULTIPLIER: 2
};

export const PRIVATE_VIEW_STATUS_CODE = {
  PROTECTED_PASSWORD: 409,
  LINK_EXPIRED: 403,
  INCORRECT_PASSWORD: 401
};
