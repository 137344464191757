import { isPlatformBrowser } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

import { STORAGE } from '@constants/storage.constant';
import { CryptoService } from '@services/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  cacheAPIs = new Map<string, HttpResponse<unknown>>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: InjectionToken<object>,
    @Inject('STORAGE') private readonly storage: Storage,
    private readonly cryptoService: CryptoService
  ) {}

  get(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const encryptedValue = this.storage.getItem(key);
        return (
          encryptedValue &&
          JSON.parse(this.cryptoService.decryptValue(encryptedValue))
        );
      } catch {
        return null;
      }
    }
  }

  set(key: string, value: object | string) {
    if (isPlatformBrowser(this.platformId)) {
      const encryptedValue = this.cryptoService.encryptValue(
        JSON.stringify(value)
      );
      this.storage.setItem(key, encryptedValue);
    }
  }

  getUserId() {
    return this.get(STORAGE.USER_DATA)._id;
  }

  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage.removeItem(key);
    }
  }

  clear() {
    if (isPlatformBrowser(this.platformId)) {
      this.storage.clear();
    }
  }

  setItem(
    key: string,
    value: object | string,
    expirationInMinutes: number
  ): void {
    if (isPlatformBrowser(this.platformId)) {
      const expirationDigit = 60000;
      const expirationTime =
        new Date().getTime() + expirationInMinutes * expirationDigit;
      const item = {
        value,
        expirationTime
      };
      sessionStorage.setItem(key, JSON.stringify(item));
    }
  }

  getItem(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      const itemStr = sessionStorage.getItem(key);

      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const currentTime = new Date().getTime();

      // Check if the item has expired
      if (currentTime > item.expirationTime) {
        sessionStorage.removeItem(key);
        return null;
      }

      return item.value;
    }
    return null;
  }

  // Remove item from storage
  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem(key);
    }
  }
}
