import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPasswordComponent } from '@auth/forgot-password/forgot-password.component';
import { IconsComponent } from '@components/icons/icons.component';
import {
  FADE_ANIMATION,
  OPEN_MODEL_ANIMATION
} from '@constants/animation.constants';
import { environment } from '@environment/environment';
import { PasswordType } from '@models/auth.model';
import { AuthService } from '@services/auth.service';
import { DialogService } from '@services/dialog.service';
import { EnvironmentLoaderService } from '@services/environment-loader.service';
import { ToasterService } from '@services/toaster.service';
import { VcButtonComponent } from '@vc-libs/vc-button/vc-button.component';
import { VcInputComponent } from '@vc-libs/vc-input/vc-input.component';
import { finalize } from 'rxjs';

const modules = [FormsModule];
const components = [VcButtonComponent, VcInputComponent, IconsComponent];

@Component({
  selector: 'app-login',
  imports: [...modules, ...components],
  templateUrl: './login.component.html',
  animations: [FADE_ANIMATION, OPEN_MODEL_ANIMATION]
})
export class LoginComponent {
  isSubmitted = false;
  passwordFieldType: PasswordType = 'password';
  readonly destroyRef = inject(DestroyRef);

  readonly logo = EnvironmentLoaderService.environmentContent.logo;
  readonly awsUrl = environment.awsUrl;

  @Input() data!: {
    redirectToHome?: boolean;
  };

  constructor(
    private readonly authService: AuthService,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    private readonly router: Router
  ) {}

  onSubmit(loginForm: NgForm): boolean | void {
    if (loginForm.invalid) {
      return;
    }

    this.isSubmitted = true;
    this.authService
      .login(loginForm.value)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => (this.isSubmitted = false))
      )
      .subscribe((res) => {
        this.popupClose();
        this.toasterService.success(res.message);
        if (this.data?.redirectToHome) {
          this.router.navigate(['/']);
        }
      });
  }

  popupClose() {
    this.dialogService.close();
  }

  openRegister() {
    this.authService.openSignup();
  }

  openForgot() {
    this.dialogService.dialog$.next({
      component: ForgotPasswordComponent,
      data: null
    });
  }

  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }
}
